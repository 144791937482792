import React from "react"
import {Link} from 'gatsby';

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="container-fluid not-found">
      <div className="row">
        <div className="col-lg-12">
          <div>
            <h1>404: PAGE NOT FOUND</h1>
            <p>You just hit a route that doesn&#39;t exist...</p>
            <Link to="/" className="line-below line-reverse text-white">
            Go back home
            </Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
